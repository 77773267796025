/* src/LandingPage.css */
.landing-page {
    padding: 20px;
  }
  
  header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  header h1 {
    font-size: 48px;
  }
  
  .customer-retention {
    background-color: #f8f8f8;
    padding: 50px 0;
  }
  
  .customer-retention h2 {
    font-size: 36px;
  }
  
  .coupon-icon {
    font-size: 128px;
  }
  
  footer {
    text-align: center;
    margin-top: 30px;
    padding: 10px;
    background-color: #f8f8f8;
  }

  